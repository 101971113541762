var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.functionbox_uuid
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "b-dropdown",
                {
                  staticClass: "filter-dropdown",
                  attrs: {
                    id: "dropdown-1",
                    size: "sm",
                    text: _vm.filterTextArchived,
                    "toggle-class": _vm.filterClassArchived,
                  },
                },
                [
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            switch: "",
                            size: "sm",
                            name: "check-button",
                            value: "0",
                          },
                          on: { change: _vm.getSent },
                          model: {
                            value: _vm.filter_archived,
                            callback: function ($$v) {
                              _vm.filter_archived = $$v
                            },
                            expression: "filter_archived",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ARCHIVED.0")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            switch: "",
                            size: "sm",
                            name: "check-button",
                            value: "1",
                          },
                          on: { change: _vm.getSent },
                          model: {
                            value: _vm.filter_archived,
                            callback: function ($$v) {
                              _vm.filter_archived = $$v
                            },
                            expression: "filter_archived",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ARCHIVED.1")))]
                      ),
                    ],
                    1
                  ),
                  _vm.filter_archived.length != 0
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.filter_archived.length != 0
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.clearFilterArchived } },
                        [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("b-table", {
        key: "sent_" + _vm.redrawIndex,
        staticClass: "mt-2",
        attrs: {
          small: "",
          bordered: "",
          items: _vm.items,
          hover: "",
          fields: _vm.fields,
          busy: _vm.loading,
        },
        on: { "row-clicked": _vm.clickedRow },
        scopedSlots: _vm._u([
          {
            key: "head(message.has_attachment2)",
            fn: function () {
              return [
                _vm.items.length != 0 && 1 == 0
                  ? _c("b-form-checkbox", {
                      on: { change: _vm.addAll },
                      model: {
                        value: _vm.add_all,
                        callback: function ($$v) {
                          _vm.add_all = $$v
                        },
                        expression: "add_all",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(message.created_date)",
            fn: function (data) {
              return [
                _vm._v(
                  _vm._s(_vm.FormatDateTime(data.item.message.created_date))
                ),
              ]
            },
          },
          {
            key: "cell(message.size)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.bytesToSize(data.item.message.size)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(message.participants)",
            fn: function (data) {
              return [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: { title: _vm.showViewedTitle(data.item) },
                  },
                  [
                    _vm.status(data.item) == 0
                      ? _c("i", { staticClass: "fal fa-circle" })
                      : _vm._e(),
                    _vm.status(data.item) == 1
                      ? _c("i", { staticClass: "fal fa-circle yellow" })
                      : _vm._e(),
                    _vm.status(data.item) == 2
                      ? _c("i", { staticClass: "fal fa-circle green" })
                      : _vm._e(),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.showViewed(data.item)) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(message.has_attachment2)",
            fn: function (data) {
              return [
                _vm.drafts && 1 == 0
                  ? _c("b-form-checkbox", {
                      attrs: {
                        name: "check-button",
                        value: data.item.message.message_uuid,
                      },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(message.has_attachment)",
            fn: function (data) {
              return [
                _c("img", {
                  staticClass: "icon mr-2",
                  attrs: {
                    src: _vm.messageIcon(data.item.message.message_type),
                    alt: _vm.$t(
                      "MESSAGES.TYPE." + data.item.message.message_type
                    ),
                  },
                }),
                data.item.message.archived
                  ? _c("span", [_c("i", { staticClass: "fal fa-archive" })])
                  : _vm._e(),
                data.item.message.has_attachment
                  ? _c("span", [_c("i", { staticClass: "fal fa-paperclip" })])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(message.id)",
            fn: function (data) {
              return [
                _c("img", {
                  staticClass: "icon mr-2",
                  attrs: {
                    src: _vm.messageIcon(data.item.message.message_type),
                    alt: _vm.$t(
                      "MESSAGES.TYPE." + data.item.message.message_type
                    ),
                  },
                }),
                data.item.message.archived
                  ? _c("span", [_c("i", { staticClass: "fal fa-archive" })])
                  : _vm._e(),
                data.item.message.has_attachment
                  ? _c("span", [_c("i", { staticClass: "fal fa-paperclip" })])
                  : _vm._e(),
                _c("div", { staticClass: "text-break" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(data.item.message.subject) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "time-information f12" }, [
                  _vm._v(
                    _vm._s(_vm.FormatDateTime(data.item.message.created_date))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "f12" },
                  [
                    _c("SentRecipients", {
                      attrs: {
                        message_uuid: data.item.message.message_uuid,
                        external: data.item.external,
                        external_system_participant:
                          data.item.external_system_participant,
                        participants: data.item.participants,
                        draft: _vm.drafts == "true" ? true : false,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(message.loa_level)",
            fn: function (data) {
              return [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "fal fa-info-square",
                  attrs: {
                    title: _vm.$t("LOA.INFO." + data.item.message.loa_level),
                  },
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("LOA.LEVEL." + data.item.message.loa_level)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(message.custom_identifier)",
            fn: function (data) {
              return [
                _c("SentRecipients", {
                  attrs: {
                    message_uuid: data.item.message_uuid,
                    external: data.item.external,
                    participants: data.item.participants,
                    external_system_participant:
                      data.item.external_system_participant,
                    draft: _vm.drafts == "true" ? true : false,
                  },
                }),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [_c("b-spinner", { staticClass: "align-middle" })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
            _c(
              "div",
              [
                _c(
                  "b-form",
                  { attrs: { inline: "" } },
                  [
                    _vm.selected.length != 0
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "danger" },
                            on: { click: _vm.removeAction },
                          },
                          [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "mt-2",
                attrs: {
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.limit,
                  align: "center",
                  pills: "",
                },
                on: { input: _vm.getSent },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }