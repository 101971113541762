<template>
  <section >

          <div v-if="!functionbox_uuid" class="mt-2">
            <b-dropdown
              id="dropdown-1"
              class="filter-dropdown"
              size="sm"
              :text="filterTextArchived"
              :toggle-class="filterClassArchived"
            >
              <b-dropdown-form>
                <b-form-checkbox
                  switch
                  @change="getSent"
                  size="sm"
                  v-model="filter_archived"
                  name="check-button"
                  value="0"
                  >{{ $t("FILTER.ARCHIVED.0") }}</b-form-checkbox
                >
              </b-dropdown-form>
              <b-dropdown-form>
                <b-form-checkbox
                  switch
                  @change="getSent"
                  size="sm"
                  v-model="filter_archived"
                  name="check-button"
                  value="1"
                  >{{ $t("FILTER.ARCHIVED.1") }}</b-form-checkbox
                >
              </b-dropdown-form>
              <b-dropdown-divider
                v-if="filter_archived.length != 0"
              ></b-dropdown-divider>
              <b-dropdown-item
                v-if="filter_archived.length != 0"
                @click="clearFilterArchived"
                >{{ $t("FILTER.CLEAR_SELECTED") }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
    
    <b-table
      small
      bordered
      :items="items"
      hover
      class="mt-2"
      :key="'sent_' + redrawIndex"
      :fields="fields"
      :busy="loading"
      @row-clicked="clickedRow"
    >

    <template #head(message.has_attachment2)>
      <b-form-checkbox
        v-if="items.length != 0 && 1 == 0"
        v-model="add_all"
        @change="addAll"
      ></b-form-checkbox>
    </template>


      <template v-slot:cell(message.created_date)="data">{{
        FormatDateTime(data.item.message.created_date)
      }}</template>
      <template v-slot:cell(message.size)="data">
        {{ bytesToSize(data.item.message.size) }}
      </template>      

      <template v-slot:cell(message.participants)="data">
        <span :title="showViewedTitle(data.item)"  v-b-tooltip.hover >
        <i class="fal fa-circle" v-if="status(data.item) == 0"></i>
        <i class="fal fa-circle yellow" v-if="status(data.item) == 1"></i>
        <i class="fal fa-circle green" v-if="status(data.item) == 2"></i>
        {{showViewed(data.item)}}
        </span>
      </template>

      <template v-slot:cell(message.has_attachment2)="data">
        <b-form-checkbox
        v-if="drafts && 1 == 0"
        v-model="selected"
        name="check-button"
        :value="data.item.message.message_uuid"
      ></b-form-checkbox>  
    </template>


      <template v-slot:cell(message.has_attachment)="data">
        <img class="icon mr-2" :src="messageIcon(data.item.message.message_type)" :alt="$t('MESSAGES.TYPE.' + data.item.message.message_type)"  />
        <span v-if="data.item.message.archived"
          ><i class="fal fa-archive"></i></span>
        <span v-if="data.item.message.has_attachment"
          ><i class="fal fa-paperclip"
        /></span>
      </template>

      <template v-slot:cell(message.id)="data">
        <img class="icon mr-2" :src="messageIcon(data.item.message.message_type)" :alt="$t('MESSAGES.TYPE.' + data.item.message.message_type)"  />
        <span v-if="data.item.message.archived"
          ><i class="fal fa-archive"></i></span>
        <span v-if="data.item.message.has_attachment"
          ><i class="fal fa-paperclip"
        /></span>
        <div class="text-break">
          {{ data.item.message.subject }}
        </div>
        <div class="time-information f12">{{
          FormatDateTime(data.item.message.created_date)
        }}</div>
        <div class="f12"><SentRecipients
          :message_uuid="data.item.message.message_uuid"
          :external="data.item.external"
          :external_system_participant="data.item.external_system_participant"
          :participants="data.item.participants"
          :draft="drafts == 'true' ? true : false"
        ></SentRecipients></div>
      </template>

      <template v-slot:cell(message.loa_level)="data">
        <i
          class="fal fa-info-square"
          v-b-tooltip.hover
          :title="$t('LOA.INFO.' + data.item.message.loa_level)"
        ></i>
        {{ $t("LOA.LEVEL." + data.item.message.loa_level) }}
      </template>
      
      <template v-slot:cell(message.custom_identifier)="data">
        <SentRecipients
          :message_uuid="data.item.message_uuid"
          :external="data.item.external"
          :participants="data.item.participants"
          :external_system_participant="data.item.external_system_participant"
          :draft="drafts == 'true' ? true : false"
        ></SentRecipients>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    


      <b-row>
        <b-col cols="12" md="6">
          <div>
            <b-form inline>
              <b-button variant="danger" v-if="selected.length != 0" @click="removeAction">{{
                $t("REMOVE")
              }}</b-button></b-form
            >
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          @input="getSent"
          pills
        >
        </b-pagination>
        </b-col>
      </b-row>

  </section>
</template>
<script>
import SentRecipients from "@/components/View/SentRecipients";
export default {
  props: ["drafts", "searchText", "limit", "functionbox_uuid", "update_message_uuid","user_uuid"],
  components: {
    SentRecipients,
  },
  data() {
    return {
      add_all: false,
      filter_archived: ["0"],
      selectedIndex: 0,
      selected: [],
      redrawIndex: 0,
      loading: false,
      message_uuid: "",
      currentPage: 1,
      items: [],
      totalItems: 0,
      state: 1,
      options: [
        { text: this.$t("ACTIVE"), value: 1 },
        { text: this.$t("INACTIVE"), value: 0 },
      ],
    };
  },
  methods: {    
    addAll: function () {
      if (this.add_all) {
        this.selected = this.items.map((a) => a.message.message_uuid);
      } else {
        this.selected = [];
      }
    },
    removeAction: function () {
      let self = this;
      let selected = this.selected;
      if (selected.length == 0) {
        return false;
      }
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            for(let ix = 0; ix < selected.length; ix++)
            {
              let remove_uuid = selected[ix];
              self.$http
                .post(self.user.hostname + "/sefos-message/secure/remove", {
                  message_uuid: remove_uuid,
                  user_uuid: self.user_uuid,
                  functionbox_uuid: self.functionbox_uuid
                })
                .then(async function () {
                  self.selected = self.selected.filter(function(item) {
                    return item != remove_uuid;
                  });
                  self.items = self.items.filter(function(item) {
                    return item.message.message_uuid != remove_uuid;
                  });
                  await self.$store.dispatch("user/fetchUser");
                })
                .catch(() => {
                });                                                  
            }                   
          }
        })
        .catch(function () {});
    },
    messageIcon(message_type)
    {
      return "/img/message_type/" + message_type + ".png";
    },
    showViewedTitle(item)
    {
      return this.showViewed(item) + " " + this.$t('VIEWED');
    },
    showViewed(item)
    {
      let external_viewed = item.external.filter(function (item) {
        return item.viewed == 1
      });
       let participants_viewed = item.participants.filter(function (item) {
        return item.fetched == 1
      });
      return (external_viewed.length + participants_viewed.length) + "/" + (item.external.length + item.participants.length);
    },
    status(item)
    {
      let external_viewed = item.external.filter(function (item) {
        return item.viewed == 1
      });
       let participants_viewed = item.participants.filter(function (item) {
        return item.fetched == 1
      });
      if( (external_viewed.length + participants_viewed.length) == (item.external.length + item.participants.length))
      {
        return 2;
      }
      if( (external_viewed.length != 0) || (participants_viewed.length != 0))
      {
        return 1;
      }
      if( (external_viewed.length == 0) && (participants_viewed.length == 0))
      {
        return 0;
      }
    },
    changeList() {
      this.currentPage = 1;
      this.getSent();
    },
    clickedRow(data,index) {
      console.log("clickedRow");
      this.selectedIndex = index;     
      if(data.message.isDraft)
      {
        this.$emit('draftMessage', data.message)
      }else{
        this.$emit("viewingMessage", data.message.message_uuid);        
      }
    },
    getSent: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/inbox/sent",
          {
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            page: this.currentPage,
            limit: parseInt(this.limit),
            state: this.state,
            search_text: this.searchText,
            type: "",
            filter_archived: this.filter_archived,
            isDraft: this.drafts == 'true' ? true : false
          }
        )
        .then((response) => {
          self.$emit("changeUpdateMessageUuid","");
          self.loading = false;
          let items = response.data.items;
          self.items = items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    UpdateItem: function(message_uuid) {
      if(message_uuid != "")
      {
        let self = this;
        this.$http
          .post(
            this.user.hostname + "/inbox/message-sent-update",
            {
              functionbox_uuid: this.functionbox_uuid,
              user_uuid: this.user_uuid,
              message_uuid: message_uuid
            }
          )
          .then((response) => {
            if( (response.data.message.removed == 1) || (response.data.message.removed == 1) )
            {
              self.items.splice(self.selectedIndex, 1);
            }else{
              self.items[self.selectedIndex] = response.data;
            }
            self.redrawIndex = self.redrawIndex + 1;
          })
          .catch(() => {
          });

      }
    },
    clearFilterArchived() {
      this.filter_archived = [];
      this.getSent();
    },
  },
  computed: {
    fields() {
      if(this.drafts && 1 == 0)
      {
        return [
          {
            key: "message.id",
            class: "hidden-not-mobile",
            thClass: "hidden",
          },
          {
            key: "message.has_attachment2",
            label: "",
            class: "text-break w-30 clickable hidden-mobile",
            sortable: false,
            thClass: "",
          },
          {
            key: "message.has_attachment",
            label: "",
            class: "text-break w-icon clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.subject",
            label: this.$t("SUBJECT"),
            class: "text-break w-300 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.custom_identifier",
            label: this.$t("RECIPIENTS"),
            class: "text-break w-300 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.created_date",
            label: this.$t("CREATED"),
            class: "text-break w-date clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.loa_level",
            label: "",
            class: "text-break w-150 clickable hidden-mobile",
            sortable: false,
          }
        ];
      }else{
        return [
          {
            key: "message.id",
            class: "hidden-not-mobile",
            thClass: "hidden",
          },
          {
            key: "message.has_attachment",
            label: "",
            class: "text-break w-icon clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.subject",
            label: this.$t("SUBJECT"),
            class: "text-break w-300 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.custom_identifier",
            label: this.$t("RECIPIENTS"),
            class: "text-break w-300 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.created_date",
            label: this.$t("CREATED"),
            class: "text-break w-date clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "message.loa_level",
            label: "",
            class: "text-break w-150 clickable hidden-mobile",
            sortable: false,
          }
        ];
      }
    },
    filterClassArchived() {
      if (this.filter_archived.length != 0) {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
    filterTextArchived() {
      if (this.filter_archived.length != 0) {
        if (this.filter_archived.length > 1) {
          return (
            this.$t("FILTER.ARCHIVED.TITLE") +
            ": " +
            this.$t(
              "FILTER.ARCHIVED." + this.filter_archived.slice().sort()[0]
            ) +
            " +(" +
            (this.filter_archived.length - 1) +
            ")"
          );
        } else {
          return (
            this.$t("FILTER.ARCHIVED.TITLE") +
            ": " +
            this.$t("FILTER.ARCHIVED." + this.filter_archived.slice().sort()[0])
          );
        }
      } else {
        return this.$t("FILTER.ARCHIVED.TITLE");
      }
    },
  },
  watch: {
    update_message_uuid: function(new_message_uuid)
    {
      this.UpdateItem(new_message_uuid);
    },
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getSent();
      }
      if (this.searchText.length >= 2) {
        this.getSent();
      }
    },
    limit: function() {
      this.getSent();
    },
    filter_archived: function(new_array)
    {
      if(this.functionbox_uuid == "")
      {
        if(this.user_uuid != "")
        {
          localStorage.setItem("message_sent_archived_" + this.user_uuid.replace(":","_"), JSON.stringify(new_array));
        }else{
          localStorage.setItem("message_sent_archived", JSON.stringify(new_array));
        }
      }
    },
  },
  mounted: function() {
    if(this.functionbox_uuid == "")
    {
      if(this.user_uuid != "")
      {
        //console.log(this.user_uuid);
        if (localStorage.getItem("message_sent_archived_" + this.user_uuid.replace(":","_")) !== null) {
          this.filter_archived = JSON.parse(localStorage.getItem("message_sent_archived_" + this.user_uuid.replace(":","_")));
        }
      }else{
         if (localStorage.getItem("message_sent_archived") !== null) {
          this.filter_archived = JSON.parse(localStorage.getItem("message_sent_archived"));
        }
      }
    }
    this.getSent();
  },
};
</script>
<style></style>
